import '../vendor/lazysizes';
import '../vendor/slick';
import '../vendor/magnific-popup.min';
import Headroom from '../vendor/headroom.min';
import $ from 'jQuery';

const Mangrove = {
  init() {
    // this.selectActiveNav();
    this.cache();
    this.events();
    this.initHeadroom();
    this.initSliders();
    this.initLoadedAnimation();
    this.animateBanner();
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-mobile-nav');
    this.$hamburger = $('.js-hamburger');
  },
  events() {
    this.$doc.on(
      'click',
      '.js-hamburger',
      event => this.showMobileNav(event)
    );
    this.$doc.on(
      'click',
      '.js-share',
      event => this.openShareWindow(event)
    );
    this.$doc.on(
      'click',
      '.js-scroll-to a',
      event => this.scrollToSection(event)
    );
    this.$doc.on(
      'click',
      'a[href^="#"]',
      event => this.scrollToSection(event)
    );
    this.$doc.on(
        'click',
        '.js-close-banner',
        event => this.closeBannerCTA(event)
    );
    this.$doc.on(
        'click',
        '.js-video-play',
        event => this.handleVideoPlay(event)
    )
  },
  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },
  initHeadroom() {
    let theHeader = document.querySelector('.js-header');
    let headroom = new Headroom(theHeader, {
      "offset": 20,
      "tolerance": 5
    });
    headroom.init();
  },
  initLoadedAnimation() {
    setTimeout(function(){
      $('#hero').addClass('is-visible');// fake the loading - add the class with delay
    },600);
  },
  showMobileNav(event) {
    event.preventDefault();
    this.$mobileNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');
    if ( this.$mobileNav.hasClass('is-active') ) {
      this.$hamburger.attr("aria-pressed", "true");
    } else {
      this.$hamburger.removeAttr("aria-pressed");
    }
  },
  scrollToSection(event) {
    const el = $(event.currentTarget);
    let section = el.attr('href');
    event.preventDefault();
    if($('.js-mobile-nav').hasClass('is-active')) {
      this.$mobileNav.toggleClass('is-active');
      this.$hamburger.toggleClass('is-active').removeAttr("aria-pressed");
    }
    if (section) {
      $('html, body').animate({
        scrollTop: ($(section).offset().top-90)
      }, 1000);
    }
  },
  initSliders() {
    $('.js-hero-slider').slick({
      initialSlide: 0,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3600,
      dots: false,
      arrows: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      fade: true
    });

    $('.js-testimonials-slider').slick({
      initialSlide: 0,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      dots: false,
      arrows: false,
      fade: true
    });
  },
  // selectActiveNav(){
  //   var currPath = window.location.pathname;
  //   console.log(currPath);
  //   $(".nav--main a").each(function( index ) {
  //     var linkPath = $(this).attr("href");
  //     if(linkPath.indexOf(currPath) >= 0){
  //       $(".nav--main a").removeClass('nav-active');
  //       $(this).addClass('nav-active');
  //       return;
  //     }
  //   });
  // },
  animateBanner() {
    if ( $( ".top-banner" ).length ) {
      $('.top-banner').addClass('animate-down');
    }
  },
  closeBannerCTA(event) {
    var el = $(event.currentTarget);
    el.closest('.top-banner').addClass('closed').removeClass('animate-down');
    $('.header').removeClass('has-banner');
    $('.main').removeClass('has-banner');
  },
  handleVideoPlay(event) {
    event.preventDefault();
    var videoItem = $(event.currentTarget);
    var videoSrc = videoItem.data('url');

    $.magnificPopup.open({
      items: {
        src: videoSrc,
        type: 'iframe',
      },
      midClick: true,
      removalDelay: 100,
      mainClass: 'popup--animate',
      iframe: {
        markup: '<div class="mfp-video-header">' +
          '<div class="mfp-close"></div>' +
          '</div>' +
          '<div class="mfp-iframe-scaler">' +
          '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
          '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
        patterns: {
          vimeo: {
            index: 'vimeo.com/',
            id: function(src) {
              if (src.indexOf('external') > -1) {
                return 'external/' + src.substr(src.lastIndexOf('/') + 1, src.length);
              } else {
                return 'video/' + src.substr(src.lastIndexOf('/') + 1, src.length);
              }
            },
            src: '//player.vimeo.com/%id%?autoplay=1&autopause=0&playsinline=1'
          }
        }
      }
    });
  },
};

Mangrove.init();
